import './App.css'
import { Router } from './pages'

function App() {

  return (
    <Router />

  )
}

export default App
