import React from "react"
// import { Avatar } from "@/entities"
// @ts-ignore
import Ready from '@/shared/assets/svg/ready.svg?react'
import styled from "@emotion/styled"

const StyledProgressDiv = styled.div<{ progress: number }>`
    width: 60px;
    height: 60px;
    border-radius: 10px;
    position: relative;
    &::after {
        content: '';
        display: block;
        position: absolute;
        left: -6px;
        top: -6px;
        bottom: -6px;
        right: -6px;
        background-color: transparent;
        background-image: ${props => `conic-gradient(orange ${props.progress}%, transparent ${props.progress}%)`};
        z-index: -1;
        z-index: -100;
        border-radius: 13px;
        transition: 0.3s;
    }
`

export const ProgressAvatar = ({ deadline, ready, nickname }: { deadline: string | null, ready: boolean, nickname: string }) => {
  const [progress, setProgress] = React.useState(0);
  React.useEffect(() => {
    setProgress(0)
    if (!deadline) return
    const updateProgress = () => {
      const now = new Date().getTime();
      const deadlineTime = new Date(deadline).getTime();
      const timeDiff = deadlineTime - now; // Time difference in milliseconds

      // Convert 30 seconds to milliseconds for comparison
      const totalDuration = 30 * 1000;

      // Calculate progress based on time left
      const calculatedProgress = Math.max(
        0,
        Math.min(100, 100 - (timeDiff / totalDuration) * 100)
      );

      setProgress(calculatedProgress);
    };
    updateProgress();

    const interval = setInterval(updateProgress, 1000);

    return () => clearInterval(interval)
  }, [deadline]);


  return (
    <StyledProgressDiv progress={progress}>
      {ready && <div className="absolute right-[-10px] top-[-10px]">
        <Ready />
      </div>}
      {/* <Avatar /> */}
      <div className="absolute top-[50%] translate-y-[-50%]">{nickname}</div>
    </StyledProgressDiv>
  )
}
