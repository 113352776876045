import { useGameStore } from "@/entities/Game"
import DeckImg from "@/shared/assets/img/blue_back.png"
import { Card } from "../Cards"



export const Deck = () => {
  const { deck, trump } = useGameStore()
  return (
    trump ? <div className="absolute font-bold left-[50%] bottom-[-100px] text-white  translate-x-[-50%]  flex flex-col items-center">
      <span className="opacity-70  mb-[40px]">
        {deck} cards left
      </span>

      <div className="shadow-[0px_0px_10px_0px_rgba(0,0,0,0.25)] rounded-[3px] bg-white p-[5px] rotate-90 relative z-10">
        <img className="w-[70px] h-[100px]" src={DeckImg} alt="" />
      </div>
      <div className="flex flex-col items-center gap-[10px]">
        <div className="w-[80px] absolute top-[30px] text-[40px] text-center flex justify-center">
          <Card suit={trump?.suit as string} dignity={trump?.dignity as string} />
        </div>
      </div>
    </div> : null
  )
}
