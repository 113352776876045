import { useGameStore } from "@/entities/Game"
import styled from "@emotion/styled"

const ModalFool = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
`


export const Fool = () => {
  const { error } = useGameStore()
  if (!error) return null
  return (
    <ModalFool>
      <div className="bg-green p-[5px]  text-[#f70707] text-3xl">{error}</div>
    </ModalFool>
  )
}
