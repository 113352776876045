import React from "react"

const useDisplayingCards = <T>(array: T[], fromTable: boolean = false) => {
  const [displayedCards, setDisplayedCards] = React.useState<T[]>([]);

  React.useEffect(() => {
      if (fromTable || array.length === 0) {
          setDisplayedCards(array);
          return;
      }

      if (displayedCards.length >= array.length) {
          if (JSON.stringify(displayedCards) !== JSON.stringify(array)) {
              setDisplayedCards(array);
          }
          return;
      }

      const timer = setTimeout(() => {
          setDisplayedCards(prev => [...prev, array[prev.length]]);
      }, 100);

      return () => clearTimeout(timer);
  }, [array, displayedCards, fromTable]);

  return displayedCards;
};

export default useDisplayingCards;
