import { StatusModal } from "../StatusModal"


export const Avatar = ({ img }: { img: string }) => {
  return (
    <img className="size-[40px] rounded-full" src={img} alt="avatar"></img>
  )
}



export const PlayerAvatar = ({ name, status, img }: { name: string, status?: string, img: string }) => {


  return <div className={"relative flex flex-col items-center text-white"}>
    <Avatar img={img} />
    <span>{name.length > 7 ? name.slice(0, 8) + '...' : name}</span>
    <div className="absolute top-0 right-[-40px]">
      <StatusModal isOpen={!!status}>
        {status}
      </StatusModal>
    </div>
  </div>
}
