import axios, { AxiosInstance, AxiosRequestConfig } from "axios"

const BASE_URL = import.meta.env.PROD ? "https://zabqer.net/api/v1" : "http://localhost:5000/api/v1";


class ApiInstance {
    private axios: AxiosInstance
    constructor() {
        this.axios = axios.create({
            baseURL: BASE_URL
        })
    }

    async get<T>(url: string, options: AxiosRequestConfig = {}): Promise<T> {
        const response = await this.axios.get<T>(url, options)
        return response.data
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async post<T>(url: string, data: any, options: AxiosRequestConfig = {}): Promise<T> {
        const response = await this.axios.post<T>(url, data, options)
        return response.data
    }

    websocket(url: string) {
        return new WebSocket(`${BASE_URL}/${url}`)
    }
}


export const api = new ApiInstance()
