import { CardTemplate, SuitIcon } from '../CardTemplate'



export const CardSix = ({ suit }: { suit: string }) => {
  return (
    <CardTemplate dignity='6' suit={suit}>
      <div className='flex flex-col justify-between h-full w-full'>
        <div className='flex justify-between'>
          <SuitIcon suit={suit} />
          <SuitIcon suit={suit} />
        </div>
        <div className='flex justify-between'>
          <SuitIcon suit={suit} />
          <SuitIcon suit={suit} />
        </div>
        <div className='flex justify-between rotate-180'>
          <SuitIcon suit={suit} />
          <SuitIcon suit={suit} />
        </div>
      </div>
    </CardTemplate >
  )
}
