//@ts-ignore
import Dialog from '@/shared/assets/svg/dialog.svg?react'
import { AnimatePresence, motion } from 'framer-motion'
import styled from '@emotion/styled'

const StyledModal = styled.div`
  background: #fff;
  border-radius: 20px;
  padding: 2px 12px 2px 16px;
  top: 0;
  font-size: 13px;
  right: 12px;
  font-weight: 500;
  z-index: 100;
  position: absolute;
  color: #000;
  `



export const StatusModal = ({ isOpen, children }: { isOpen: boolean } & React.PropsWithChildren) => {

  return (
    <AnimatePresence mode='wait' >
      {isOpen && (
        <motion.div className='relative'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3, delay: 0.3 }}
        >
          <StyledModal>
            <Dialog className="absolute bottom-0 left-[-5px]" />
            {children}
          </StyledModal>
        </motion.div>
      )
      }
    </AnimatePresence >
  )
}
