// import styled from "@emotion/styled"
// import { useGameStore } from "@/entities/Game"
// import { BgCard } from "../Cards"
// import { motion } from 'framer-motion'


// type BitoCardProps = {
//   zIndex: number
//   rotationAngle: number
//   bottomPosition: number
// }

// const BitoCard = styled.div<BitoCardProps>`
//     width: 100px;
//     height: 140px;
//     position: absolute;
//     right: 0;
//     z-index: ${props => props.zIndex};
//     transform: rotate(${props => props.rotationAngle}deg);
//     bottom: ${props => props.bottomPosition}px;
// `
// const degs = [15, -9, -15, -20, 35, -35, 45, -45, 180, -180, 30, -30, 60, -60, 120, -120, 75, -75, 150, -150];
// const position = [0, -50, 10, -40, 20, -30, 30, -20, 40, -10, 50, 0, 60, 10, 70, 20, 80, 30, 45, 40];

export const Bito = () => {
  // const { bito } = useGameStore()

  return (
    <div className="bito absolute right-0 top-[50%]"></div>
    // <div className="bito absolute right-[-80px] h-[70px] top-[150px]">
    //   {bito.map((item, index) => {
    //     const angle = degs[index % bito.length]
    //     const bottom = position[index % bito.length]
    //     return (
    //       <motion.div
    //         key={index}
    //         initial={{
    //           x: item.x,
    //           y: item.y
    //         }}
    //         animate={{
    //           x: 0,
    //           y: 0,
    //           transition: { duration: 0.1 }
    //         }}
    //       >
    //         <BitoCard zIndex={index + 1} rotationAngle={angle} bottomPosition={bottom}>
    //           <BgCard />
    //         </BitoCard>
    //       </motion.div>
    //     )
    //   })}
    // </div>
  )
}
