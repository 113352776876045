import { GameState, useGameStore, canAttack, canBeat, isCardOnTable, getTargetEl, cardCanShift, isDefenderHasCards } from "@/entities/Game";
import { Card, CardProps } from "@/shared/"
import { motion, useAnimation } from 'framer-motion';
import { useRef } from "react";
import { useShallow } from "zustand/react/shallow";

type Props = {
  index: number
  // translateX: number
  // translateY: number
  // rotate: number
} & CardProps


export const HandCard: React.FC<Props> = ({ id, suit, index, dignity }) => {

  // const { config } = useGameStore()

  const target = useRef<Element>()
  const controls = useAnimation()
  const { defender, players, addAttackingCard, shiftCard, removeCardFromHand, defendCard, currentState, tableCards, trump } = useGameStore(useShallow((state) => ({
    addAttackingCard: state.addAttackingCard,
    removeCardFromHand: state.removeCardFromHand,
    defendCard: state.defendCard,
    currentState: state.currentState,
    tableCards: state.tableCards,
    trump: state.trump,
    shiftCard: state.shiftCard,
    defender: state.defender,
    players: state.players
  })))

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDragEnd = (event: any, info: any) => {
    // if (config.game_mode === "tossable") {
    //   handleShift(event, info)
    //   return
    // }
    event
    document.querySelectorAll('.table-card').forEach((item) => item.classList.remove("red-shadow"))
    const table = document.querySelector('#table')
    if (table) {
      table.classList.remove('red-shadow')
      console.log(currentState);
      const { point } = info
      const tableRect = table.getBoundingClientRect();
      if (
        isCardOnTable(point.x, point.y, tableRect)
      ) {
        if (currentState === GameState.DEFEND) {
          const attackingId = target.current?.getAttribute("id")

          if (attackingId) {
            if (attackingId === 'shift-card') {
              if (cardCanShift({ dignity, suit }, tableCards)) {
                shiftCard({ dignity, suit, id, x: point.x, y: point.y })
                removeCardFromHand(id)
              }
              controls.start({ x: 0, y: 0, });
              return
            }
            const card = tableCards.find(card => card.attack.id === attackingId)
            if (canBeat(trump as CardProps, { dignity, suit }, card!.attack)) {
              defendCard({ dignity, suit, id, x: point.x, y: point.y }, attackingId)
              removeCardFromHand(id)
              return
            }
          }
        } else if (currentState === GameState.ATTACK || currentState === GameState.PLAYER_TOSS) {

          const defenderPlayer = players.find((player) => player.id === defender)
          if (!defenderPlayer) return

          if (canAttack({ dignity, suit }, tableCards) && isDefenderHasCards(players, defender as number, tableCards)) {
            addAttackingCard({ dignity, suit, id, x: point.x, y: point.y })
            removeCardFromHand(id)
            return
          }
        }
      }
    }
    controls.start({ x: 0, y: 0, });
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDrag = (event: any, info: any) => {
    event
    const table = document.querySelector('#table')
    if (table) {
      table.classList.add('red-shadow')
    }
    if (currentState === GameState.DEFEND) {
      const { point } = info
      const attackingEl = getTargetEl(point.x, point.y)

      if (attackingEl) {
        const { id } = attackingEl
        target.current = attackingEl
        if (id === "shift-card") {
          attackingEl.classList.add('active-shift-card')
          return
        }
        const card = attackingEl.querySelector('.table-card')
        if (card) {
          card!.classList.add('red-shadow')
        }
      } else {
        document.querySelectorAll('.table-card').forEach((item) => item.classList.remove("red-shadow"))
        const el = document.querySelector('#shift-card')
        if (!el) return
        document.querySelector('#shift-card')!.classList.remove('active-shift-card')
      }
    }

  }
  return (
    <motion.div drag
      key={suit + dignity}
      style={{
        transformOrigin: 'bottom center',
        boxShadow: "10px 10px 25px rgba(0,0,0,0.7)",
      }}
      animate={controls}
      dragMomentum={false}
      onDrag={onDrag}
      onDragEnd={handleDragEnd}
      whileDrag={{
        scale: 1.1,
        zIndex: 1000,
      }}
      className="absolute w-[90px] h-[128px] text-[30px]" data-id={index}>
      <Card dignity={dignity} suit={suit} />
    </motion.div>
  )
}
