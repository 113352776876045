import { PlayerStatus } from "@/entities/Game"
import { css, keyframes } from "@emotion/react"

type GlowColorValue = '--glow-gold' | '--glow-red' | '--glow-blue' | '--glow-none';

const createGlowAnimation = (variableName: string) => keyframes`
  0%, 100% { box-shadow: 0 0 15px 2px rgba(var(${variableName}), 0.3); }
  50% { box-shadow: 0 0 25px 4px rgba(var(${variableName}), 0.5); }
`

export const useGlowAnimation = (
  colorVariable: GlowColorValue,
) => {


  // const getGlowAnimation = (): SerializedStyles | 'none' => {
  //   const animation = createGlowAnimation(colorVariable);
  //   return css`
  //     animation: ${animation} 2s infinite;
  //   `
  // }
  const animation = createGlowAnimation(colorVariable);
    return css`
      &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        border-radius: inherit; // наследуем радиус от родителя
        z-index: -1; // помещаем псевдоэлемент позади аватара
        animation: ${animation} 2s infinite;
      }
    `

}

export const getStatusColor = (status: PlayerStatus): GlowColorValue => {
  switch (status) {
    case PlayerStatus.WIN:
      return '--glow-gold'
    case PlayerStatus.ATTACK_STATUS:
      return '--glow-red'
    case PlayerStatus.DEFEND_STATUS:
      return '--glow-blue'
    default:
      return '--glow-none'
  }
}
