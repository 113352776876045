import { GameState, canShift, useGameStore } from "@/entities/Game";
import { ShiftCard, TablePair } from "@/features";
import { CardPair } from "@/entities/Game";
import styled from "@emotion/styled";

// const mockTableCards: CardPair[] = [
//   {
//     attack: {
//       id: "1",
//       x: 0,
//       y: 0,
//       suit: "SUIT_CLUB",
//       dignity: "7",
//     },
//     defend: {
//       id: "1",
//       x: 0,
//       y: 0,
//       suit: "SUIT_SPADE",
//       dignity: "10",
//     }
//   },
//   {
//     attack: {
//       id: "1",
//       x: 0,
//       y: 0,
//       suit: "SUIT_CLUB",
//       dignity: "12",
//     },
//     defend: {
//       id: "1",
//       x: 0,
//       y: 0,
//       suit: "SUIT_SPADE",
//       dignity: "10",
//     }
//   },
//   {
//     attack: {
//       id: "1",
//       x: 0,
//       y: 0,
//       suit: "SUIT_SPADE",
//       dignity: "8",
//     },
//     defend: {
//       id: "1",
//       x: 0,
//       y: 0,
//       suit: "SUIT_SPADE",
//       dignity: "10",
//     }
//   },
//   {
//     attack: {
//       id: "1",
//       x: 0,
//       y: 0,
//       suit: "SUIT_SPADE",
//       dignity: "10",
//     },
//     defend: {
//       id: "1",
//       x: 0,
//       y: 0,
//       suit: "SUIT_SPADE",
//       dignity: "10",
//     }
//   },
//   {
//     attack: {
//       id: "1",
//       x: 0,
//       y: 0,
//       suit: "SUIT_HEART",
//       dignity: "11",
//     },
//     defend: {
//       id: "1",
//       x: 0,
//       y: 0,
//       suit: "SUIT_SPADE",
//       dignity: "10",
//     }
//   },
//   {
//     attack: {
//       id: "1",
//       x: 0,
//       y: 0,
//       suit: "SUIT_HEART",
//       dignity: "10",
//     },
//     defend: {
//       id: "1",
//       x: 0,
//       y: 0,
//       suit: "SUIT_SPADE",
//       dignity: "10",
//     }
//   }

// ]

const StyledTable = styled.div`
  row-gap: 40px;
  width: 100%;
  min-height: 250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 10px;
  z-index: 20;
  gap: 50px;
  /* margin-bottom: 170px; */
`

export const Table = () => {
  const { tableCards, currentState, config } = useGameStore()

  const isShift = canShift(tableCards) && currentState === GameState.DEFEND && config.game_mode === "transferrable"



  return (
    <StyledTable id="table">
      {
        tableCards.map((card: CardPair, index) =>
          <TablePair index={index} key={card.attack.id} id={card.attack.id}  {...card} />)
      }
      {
        isShift && <ShiftCard />
      }
    </StyledTable>
  )
}
