import { CardProps } from "@/shared"
import { CardPair, EnemyPlayer } from "../model"

export const isCardOnTable = (x: number, y: number, tableRect: DOMRect): boolean => {
  if (
    x >= tableRect.left &&
    x <= tableRect.right &&
    y >= tableRect.top &&
    y <= tableRect.bottom
  ) {
    return true
  }
  return false
}

export  const getTargetEl = (x: number, y: number) => {
  const attackingCards = document.querySelectorAll('.attacking-card')
  for (let i = 0; i < attackingCards.length; i++) {
    const card = attackingCards[i];
    const rect = card.getBoundingClientRect();
    if (
      x >= rect.left && x <= rect.right &&
      y >= rect.top && y <= rect.bottom
    ) {
      return card
    }
  }
}

export const isDefenderHasCards = (players: EnemyPlayer[], defender: number, tableCards: CardPair[]) => {
  const defenderPlayer = players.find((player) => player.id === defender)
  const attackCards = tableCards.filter((card) => !card.defend)
  if (!defenderPlayer) return

  return attackCards.length + 1 <= defenderPlayer.count
}

export  const canBeat = (trump: Omit<CardProps, "id">, card: Omit<CardProps, "id">, targetCard: Omit<CardProps, "id">) => {
  const trumpSuit = trump?.suit
  const cardDignity = Number(card.dignity);
  const targetCardDignity = Number(targetCard.dignity);
  if (card.suit === trumpSuit) {
    if (targetCard.suit !== trumpSuit) return true
    if (cardDignity > targetCardDignity) return true
  }
  if (card.suit === targetCard.suit && cardDignity > targetCardDignity) return true
  return false
}

export const canAttack = (card: Omit<CardProps, "id">, tableCards: CardPair[]) => {
    if (!tableCards.length) return true
    if (tableCards.length >= 6) return false
    if (!tableCards.some((pair: CardPair) => pair.attack.dignity === card.dignity || pair.defend?.dignity === card.dignity)) return false
    return true
}

const checkLenghTableCards = (tableCards: CardPair[]) => {
  if (!tableCards.length) return false
  if (tableCards.length >= 6) return false
  return true
}

export const canShift = (tableCards: CardPair[]) => {
  if(!checkLenghTableCards(tableCards)) return false
  if (tableCards.some((pair: CardPair) => pair.defend)) return false
  const firstDignity = tableCards[0].attack.dignity
  if (tableCards.length === 4 && tableCards.every((pair: CardPair) => pair.attack.dignity === firstDignity)) return false
  return true
}


export const cardCanShift = (card: Omit<CardProps, "id">, tableCards: CardPair[]) => {
  if (tableCards.length === 4 && tableCards.every((pair: CardPair) => pair.attack?.dignity === card.dignity)) return false
  if(canShift(tableCards) && tableCards.some((pair: CardPair) => pair.attack.dignity === card.dignity)) return true
  return false
}
