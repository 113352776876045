import { Button } from "@/shared"
import { GameState, PlayerStatus, useGameStore } from "@/entities/Game"
import { PlayerAvatar, Timer } from "@/entities"
import { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { getStatusColor, useGlowAnimation } from "@/entities/Player/lib"



const StyledFooter = styled.footer <{ glowAnimation: any }>`
  position: absolute;
  bottom: 26px;
  left: 10px;
  right: 10px;
  padding: 10px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  background: rgba(46, 46, 46, 0.98);
  border: 1px solid #3D3D3D;
  backdrop-filter: blur(10px);
  z-index: 100;
  color: white;
  font-size: 20px;
  gap: 5px;
  ${({ glowAnimation }) => glowAnimation}
`

const ErrorMessage = styled.div`
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ff4444;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  z-index: 1000;

`
export const Footer = () => {
  const [error, setError] = useState<string | null>(null);
  const { deadline, currentState, setReadyStatus: setReady, readyStatus, player, tableCards, endTurn, pickupCards, setCurrentState } = useGameStore()
  const statusColor = getStatusColor(player?.status || PlayerStatus.EMPTY)
  const glowAnimation = useGlowAnimation(statusColor)

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  const canBito = (cards: typeof tableCards) => {
    try {
      return cards.length && cards.every((card) => card.defend)
    } catch (err) {
      setError(`Error checking bito: ${err instanceof Error ? err.message : String(err)}`);
      return false;
    }
  }

  const canPickup = (cards: typeof tableCards) => {
    try {
      return cards.some((card) => card.attack && !card.defend)
    } catch (err) {
      setError(`Error checking pickup: ${err instanceof Error ? err.message : String(err)} `);
      return false;
    }
  }

  const setPass = () => {
    try {
      endTurn()
      setCurrentState(GameState.WAIT)
    } catch (err) {
      setError(`Error passing turn: ${err instanceof Error ? err.message : String(err)} `);
    }
  }

  const setBito = () => {
    try {
      endTurn()
      setCurrentState(GameState.WAIT)
    } catch (err) {
      setError(`Error setting bito: ${err instanceof Error ? err.message : String(err)} `);
    }
  }

  const setPickup = () => {
    try {
      pickupCards()

    } catch (err) {
      setError(`Error picking up cards: ${err instanceof Error ? err.message : String(err)} `);
    }
  }

  return (
    <>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <StyledFooter glowAnimation={glowAnimation}>
        <div className="flex-[0_1_50px]">
          {currentState === GameState.WAIT && null}
          {currentState === GameState.AWAIT_READY && !readyStatus &&
            <Button onClick={() => setReady(true)}>Ready</Button>}
          {currentState === GameState.AWAIT_READY && readyStatus &&
            <Button onClick={() => setReady(false)}>Unready</Button>}
          {currentState === GameState.ATTACK && !tableCards.length
            && "ATTACK"}
          {currentState === GameState.DEFEND && !tableCards.length
            && "DEFEND"}
          {currentState === GameState.PLAYER_TOSS &&
            <Button onClick={setPass}>Pass</Button>}
          {!!canBito(tableCards) && currentState === GameState.ATTACK &&
            <Button onClick={setBito}>BITO</Button>}
          {canPickup(tableCards) && currentState === GameState.DEFEND &&
            <Button onClick={setPickup}>Take</Button>}
        </div>
        {player && <div className="relative flex-initial">
          <PlayerAvatar img={player.photo_url} name={player.username || player.first_name} status={player.status} />
        </div>}
        <Timer deadline={deadline ? deadline : "0"} />
      </StyledFooter>
    </>
  )
}
