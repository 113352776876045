import { create } from "zustand"
import { GameStore, GameState, TableCardType } from "./types"
import { gameApi, socketService } from "@/shared/api"
import {  GameSocketHandlerName, GameSocket } from "../lib/socket"
import * as Sentry from '@sentry/react';


export const useGameStore = create<GameStore>()((set, get) => ({
    roomId: null,
    socket: null,
    players: [],
    attackCards: {},
    attacker: null,
    defender: null,
    trump: null,
    player: null,
    deck: 36,
    bito: [],
    fromTable: false,
    hand: [],
    tableCards: [],
    currentState: GameState.WAITING,
    hasMadeMove: false,
    readyStatus: false,
    fool: null,
    config: {
      max_players: 0,
      game_mode: "normal",
  },
  error: null,
  deadline: null,
  sync: () => {
    socketService.sendMessage({ action: "sync", data: {} })
  },
  sendProblem: () => {
    const { socket, roomId, error } = get()
    const states : { [key: number]: string } = {
      0: "CONNECTING",
      1: "OPEN",
      2: "CLOSING",
      3: "CLOSED"
    };
    const socketState = states[socket!.readyState] || "UNKNOWN";
    Sentry.captureException(new Error(`Socket Error: ${error} Socket State: ${socketState} RoomId: ${roomId}`));
  },

  connect: async (roomId: string) => {
    const gameSocket = new GameSocket(set, get)
    try {
      const socket = await socketService.connect(`connect/${roomId}`)
      set({ socket, roomId })
      socketService.addMessageHandler((message) => {
        //@eslint-disable-next-line @typescript-eslint/no-explicit-any
          const handler: any = gameSocket[message.name as GameSocketHandlerName]
        if (!handler) throw Error("Game socket handler not found " + message.name)
        try {
          handler.call(gameSocket, message)
        } catch (error) {
          document.body.innerHTML = (error as Error).message
        }
        })
    } catch (error) {
      set({ error: (error as Error).message })
    }

    },
    setReadyStatus(ready: boolean) {
      socketService.sendMessage({
        action: "ready", data: {
          "state": ready
        }})
    },
  disconnect: () => () => {
    socketService.disconnect()
    gameApi.reset()
  },
  pickupCards:() => {
    socketService.sendMessage({ action: "pickup", data: {} })
  },
  endTurn: () => {
    socketService.sendMessage({ action: "endturn", data: {} })
    },
  removeCardFromHand: (cardId: string) => set((state) => {
      const hand = state.hand.filter((card) => card.id !== cardId)
      return { hand }
  }),
  cardsToBito: () => set((state) => {
      // const { tableCards } = state
      return state
  }),
  addAttackingCard: (card: TableCardType,) => {
        set((state) => {
            const attackCards = {
                ...state.attackCards,
                [card.id]: card
            }
          return {
                attackCards,
                hasMadeMove: true
            }
        })
        socketService.sendMessage({
            action: "attack", data: {
            card: { suit: card.suit, dignity: card.dignity}
        } })
    },
    defendCard: (card: TableCardType, cardId: string) => {
        const pair = get().tableCards.find(card => card.attack.id === cardId)
        socketService.sendMessage({
            action: "defend",
            data: {
                target: {
                    suit: pair?.attack?.suit,
                    dignity: pair?.attack?.dignity
                }, card:
                    { suit: card.suit, dignity: card.dignity }
            }
        })
  },
  shiftCard: (card: TableCardType) => {
    set((state) => {
      const attackCards = {
          ...state.attackCards,
          [card.id]: card
      }
    return {
          attackCards,
          hasMadeMove: true
      }
    })
    socketService.sendMessage({
      action: "shift", data: {
      card: { suit: card.suit, dignity: card.dignity}
  } })

  },
  setCurrentState: (state: GameState) => set({ currentState: state }),

  get mode (): "normal" | "transferrable" {
    return this.config.game_mode
  }
}))
