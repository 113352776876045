import { CardSix } from "@/shared/ui/Cards/CardSix";
import { CardSeven } from "@/shared/ui/Cards/CardSeven";
import { CardEight } from "@/shared/ui/Cards/CardEight";
import { CardNine } from "@/shared/ui/Cards/CardNine";
import { CardTen } from "@/shared/ui/Cards/CardTen";
import { CardAce } from "@/shared/ui/Cards/CardAce";
import { CardImage } from "../CardImage";

import React from "react";




export const Card = ({ dignity, suit }: { dignity: string, suit: string }) => {

  const dignities: Record<string, JSX.Element> = {
    6: <CardSix suit={suit} />,
    7: <CardSeven suit={suit} />,
    8: <CardEight suit={suit} />,
    9: <CardNine suit={suit} />,
    10: <CardTen suit={suit} />,
    11: <CardImage suit={suit} dignity={11} />,
    12: <CardImage suit={suit} dignity={12} />,
    13: <CardImage suit={suit} dignity={13} />,
    14: <CardAce suit={suit} />
  }

  const cardComponent = dignities[dignity]

  if (!cardComponent) return null
  return (
    React.cloneElement(cardComponent, {
      suit
    })
  )
}
