// @ts-ignore
import ShiftArrow from '@/shared/assets/svg/shiftArrow.svg?react'



export const ShiftCard = () => {
  return (
    <div id="shift-card" className="attacking-card rotate-12 rounded-[4px] flex justify-center items-center w-[90px] h-[130px] opacity-70 border-white border-dashed border-[3px]">
      <ShiftArrow />
    </div>
  )
}
