import { Hand } from '@/entities/Player'
import { Table, Footer, Fool, EnemyPlayers } from '@/widgets'
import { Bito, Container, Deck } from '@/shared/'
import { useGameStore } from '@/entities/Game'
import { init } from '@telegram-apps/sdk';

import React from 'react'
// import { jwtDecode } from "jwt-decode";




export const Room = () => {

  const { socket, connect, disconnect, sendProblem, sync } = useGameStore()
  const location = window.location

  const joinRoom = React.useCallback(() => {
    const params = new URLSearchParams(location.search);
    const uiid = params.get("tgWebAppStartParam");
    if (uiid) {
      connect(uiid)
    } else {
      console.log(params)
    }
  }, [connect, location.search])

  React.useEffect(() => {
    if (import.meta.env.PROD) {
      init()
    }
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        console.log("VISIBLE")
        if (!socket?.readyState) return
        console.log(socket.readyState)
        if (socket.readyState === 3) {
          console.log("RECONNECT")
          joinRoom()
        }
      }
    }
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    }
  }, [joinRoom, socket?.readyState])


  React.useEffect(() => {
    joinRoom()
    return () => {
      disconnect()
    }
  }, [joinRoom, disconnect])



  return (
    <div className=" h-full flex flex-col justify-between relative z-10">
      <Container>
        <div className="absolute top-0 left-0 w-full flex justify-between">
          <button className="bg-white"
            onClick={() => sendProblem()}>ERROR</button>
          <button className="bg-white"
            onClick={() => sync()}>SYNC</button>
        </div>
        <div className='flex flex-col h-full justify-between gap-[90px]'>
          <div className='h-[25vh] relative'>
            <EnemyPlayers />
            <Deck />
          </div>
          <div className='relative  flex flex-col justify-between h-full'>
            <Table />
            <Bito />
            <Fool />
            <Hand />
            <Footer />
          </div>
        </div>
      </Container>
    </div>
  )
}
