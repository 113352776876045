let BASE_URL;
import { retrieveLaunchParams } from '@telegram-apps/sdk';
// import axios from 'axios';
let initData = ''
if (import.meta.env.PROD) {
  BASE_URL = "wss://zabqer.net/api/v1"
  const { initDataRaw } = retrieveLaunchParams();
  initData = encodeURIComponent(initDataRaw as string)
} else {
  BASE_URL = "ws://localhost:5000/api/v1"
  // initData = encodeURIComponent('user=%7B%22id%22%3A1721399662%2C%22first_name%22%3A%22%3C%3E%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22JeanValjean1%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%2C%22photo_url%22%3A%22https%3A%5C%2F%5C%2Ft.me%5C%2Fi%5C%2Fuserpic%5C%2F320%5C%2F2H6KMlRZwcbOOW_fkoDY8N0eIqUjvdgtR7eeuuUpDm0.svg%22%7D&chat_instance=1312249681185152715&chat_type=group&start_param=19b37f60-d81d-4f49-9bc3-dfec0b38bd8c&auth_date=1733089709&signature=YUyAXyUtdUElUlIzrO_bN5oFt4K414e9hQ1AqiMDUuj9Spbq9K0bt4n4OW5WFOv3B7JTXnVO05-K6z0MBbU7Cg&hash=8daadf5fb9b3f40c60ebac41aa38006de909fbf22d8f3ccd90f931b1f79f639e')
  initData = ''
}

export type SocketMessage<T = unknown> = {
    name: string,
    data: T
}

const createSocketService = () => {
    let socket: WebSocket | null = null;
    let messageHandlers: ((message: SocketMessage) => void)[] = [];

    const connect = (token: string): Promise<WebSocket> => {
      return new Promise((resolve, reject) => {
        const jwt = token.replace("connect/", "");
        socket = new WebSocket(`${BASE_URL}/lobbies/${jwt}/connect?tgWebAppData=${initData}`);

        socket.onopen = () => {
          console.log("WebSocket connected");
          resolve(socket as WebSocket);
        };

        socket.onmessage = (event) => {
          const message: SocketMessage = JSON.parse(event.data);
          messageHandlers.forEach(handler => handler(message));
        };

        socket.onerror = (error) => {
          console.error("WebSocket error:", error);
          reject(new Error("Something went wrong (websocket error)"));
        };

        socket.onclose = () => {
          console.log("WebSocket disconnected");
        };
      });
    };
  const disconnect = () => {
      console.log(socket)
      if (socket) {
          socket.close();
          socket = null;
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const sendMessage = (message: any) => {
        if (socket && socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify(message))
        } else {
            console.error("Websocket is not connected!")
        }
    }

    const addMessageHandler = (handler: (message: SocketMessage) => void) => {
        messageHandlers.push(handler)
    }

    const removeMesageHandler = (handler: (message: SocketMessage) => void) => {
        messageHandlers = messageHandlers.filter(h => h !== handler)
    }

  return {
        connect,
        disconnect,
        sendMessage,
        addMessageHandler,
        removeMesageHandler
    }
}

export const socketService = createSocketService()
