// import { Player } from './../model/types';
// import { TableCard } from './../ui/TableCard/index';
// import { TablePair } from '@/features';
// import { TablePair } from './../../../features/Game/TablePair/index';
import { SocketMessage } from "@/shared/api";
import { CardPair, EnemyPlayer, GameRoomConfig, GameState, GameStore,  Player, PlayerStatus, TableCardType } from "../model";
import { CardProps } from "@/shared";

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GameSocketHandler = (message: SocketMessage<any>) => void
export type GameSocketHandlerName = keyof typeof GameSocket.prototype


type ClientEvent = { client: EnemyPlayer }
type ErrorEvent = { message: string}
type CardDistributionEvent = { cards: Omit<CardProps, "id">[], player: number, count?: number }


type RoomInfo = {
  players: (EnemyPlayer | Player)[],
  hand?: CardProps[],
  trump?: Omit<CardProps, "id">,
  game_state: PlayerTurnEvent,
  deck: number
  config: GameRoomConfig
}

type GameStateEvent<T = unknown> = {
  game_state: GameState
  game_state_data: T
}
type AwaitReadyStatusEvent = { players: number[] }

type PlayerTurnEvent = {
  attacker: number
  defender: number
  neighbor: number
  table: CardPair[]
  attacker_turn_ended: boolean
  defender_can_defend: boolean
  neighbor_turn_ended: boolean
  neighbor_can_attack: boolean
  deadline: string
  serverTime: Date
  toss_available: boolean
}

// type TossEvent = {
//   attacker: number
//   defender: number
//   neighbor: number
//   attacker_turn_ended: boolean
//   table: CardPair[]
// }

const getCardsWithId = (cards: CardProps[]) => cards.map((card) => ({ ...card, id: card.suit + card.dignity }))

const sortByTrumpDignity = (cards: CardProps[], trump: Omit<CardProps, "id">)  => {
  const cardsWithId = getCardsWithId(cards)
  return cardsWithId.sort((a, b) => {
    if (a.suit === trump?.suit && b.suit !== trump?.suit) return 1;
    if (a.suit !== trump?.suit && b.suit === trump?.suit) return -1;
    if (a.suit < b.suit) return -1;
    if (a.suit > b.suit) return 1;
    return +a.dignity - +b.dignity
  })
}



export class GameSocket {
  private gameStateHandler: GameStateHandler
  constructor(
    private set: (partial: GameStore | Partial<GameStore> | ((state: GameStore) => GameStore | Partial<GameStore>), replace?: boolean | undefined) => void,
    private get: () => GameStore
  ) {
    this.gameStateHandler = new GameStateHandler(this.set, this.get)
  }

  room_info(message: SocketMessage<RoomInfo>): void {
    const { players, trump, game_state, deck, config } = message.data;
    function isPlayer(player: EnemyPlayer | Player): player is Player {
      return 'cards' in player;
    }
    function isEnemyPlayer(player: EnemyPlayer | Player): player is EnemyPlayer {
      return 'count' in player;
    }
    const player = players.find(isPlayer) as Player;
    const enemyPlayers = players.filter(isEnemyPlayer)
      .map((player) => ({ ...player, online: true }));

    if (trump) {
      this.set({
        trump,
        hand: sortByTrumpDignity(player.cards, trump),
        players: enemyPlayers,
        player,
        deck,
      });
      this.gameStateHandler.playerTurnHandler(game_state);
      return;
    }
    this.set({ players: enemyPlayers, player, config });
  }

  user_joined(message: SocketMessage<ClientEvent>) {
    const { client } = message.data
    const { player } = this.get()
    client.status = PlayerStatus.EMPTY
    if (player?.id === client.id) return

    this.set((state) => {
      if (state.players.find((player) => player.id === client.id)) {
        return {
          players: state.players.map((player) => {
            if (player.id === client.id) return { ...player,  status: PlayerStatus.EMPTY }
            return player
          })
        }
      }
      return { players: [...state.players, client] }
    })
  }
  error(message: SocketMessage<ErrorEvent>) {
    const { data } = message
    this.set(({ error: data.message }))
  }

  user_leaved(message: SocketMessage<ClientEvent>) {
    const { client } = message.data
    this.set((state) => ({
      players: state.players.map((player) => {
        if (player.id === client.id) return { ...player, status: PlayerStatus.DISCONNECT }
        return player
      })
    }))
  }
  user_dropped(message: SocketMessage<ClientEvent>) {
    const { client } = message.data
    this.set((state) => ({
      players: state.players.filter((player) => player.id !== client.id)
    }))
  }
  game_state_updated(message: SocketMessage<GameStateEvent>) {
    const data = message.data
    switch (data.game_state) {
      case GameState.AWAIT_READY: {
        const { game_state_data } = data as GameStateEvent<AwaitReadyStatusEvent>
        this.gameStateHandler.awaitReadyHandler(game_state_data.players)
        break;
      }
      case GameState.PLAYER_TURN: {
        const { game_state_data } = data as GameStateEvent<PlayerTurnEvent>
        this.gameStateHandler.playerTurnHandler(game_state_data)
        break;
      }
      case GameState.GAMEOVER: {
        const { game_state_data } = data as GameStateEvent<{ fool: number }>
        this.gameStateHandler.gameover(game_state_data.fool)
        break;
      }

      // case GameState.PLAYER_TOSS: {
      //   const { game_state_data } = data as GameStateEvent<TossEvent>
      //   this.gameStateHandler.playerTossHandler(game_state_data)
      //   break
      // }
      case GameState.PREPARE: {
        // this.gameStateHandler.prepareHandler()
        break;
      }
      default:
        break;
    }
  }

  leave(message: SocketMessage<ClientEvent>) {
    const { client } = message.data
    this.set((state) => ({ players: state.players.filter((player) => player.id !== client.id) }))
  }

  sortByTrumpDignity(newCards: CardProps[]) {
    const { hand, trump } = this.get()
    const allCards = [...hand, ...newCards]
    return allCards.sort((a, b) => {
      if (a.suit === trump?.suit && b.suit !== trump?.suit) return 1;
      if (a.suit !== trump?.suit && b.suit === trump?.suit) return -1;
      if (a.suit < b.suit) return -1;
      if (a.suit > b.suit) return 1;
      return +a.dignity - +b.dignity
    })
  }

  card_distribution(message: SocketMessage<CardDistributionEvent>) {
    const { cards, player, count } = message.data
    if (cards) {
      const cardsWithId = cards.map((card,) => ({ ...card, id: card.suit + card.dignity  }))
      this.set((state) => {
        const sortedByTrumpDignity = this.sortByTrumpDignity(cardsWithId)
        return {
          hand: sortedByTrumpDignity,
          deck: state.deck - cards.length
        }
      })
    }
    else {
      this.set((state) => {
        if (!count) return state
        const enemyPlayer = state.players.find((p) => p.id === player)
        const addedCount = count - (enemyPlayer!.count)
        const players = state.players.map((enemy) => {
          if (enemy.id === player) {
            return {
              ...enemy,
              count
            }
          }
          return enemy
        }) as EnemyPlayer[]
        return {
          deck: state.deck - addedCount,
          players
        }
      })
    }
  }

  trump_selected(message: SocketMessage<{ trump: Omit<CardProps, "id"> }>) {
    const { trump } = message.data
    this.set({ trump })
  }

  cards_discarded(message: SocketMessage<{ cards: Omit<CardProps, "id">[] }>) {
    message
    const tablePairs = document.querySelectorAll('.table-pair')
    const bito = document.querySelector('.bito')
    const bitoRect = bito!.getBoundingClientRect()
    const positions = Array.from(tablePairs).map((item) => {
      const rect = item.getBoundingClientRect()
      return {
        x: rect.left - bitoRect.left,
        y: rect.top - bitoRect.top
      }
    })
    this.set((state) => ({
      bito: [...state.bito, ...positions, ...positions]
    }));
  }
  cards_pickup(message: SocketMessage<{ cards: Omit<CardProps, "id">[] }>) {
    this.set((state) => {
      const { cards } = message.data
      if (state.currentState === GameState.PICKUP || state.currentState === GameState.DEFEND) {
        const cardsWithId = cards.map((card,) => ({ ...card, id: card.suit + card.dignity }))
        const sortedByTrumpDignity = this.sortByTrumpDignity(cardsWithId)
        return { hand: sortedByTrumpDignity }
      } else {
        const players = state.players.map((player) => {
          if (player.id === state.defender) {
            return {
              ...player,
              count: player.count + cards.length
            }
          }
          return player
        })
        return { players }
      }
    })
  }
  shifted(message: SocketMessage<{ card: Omit<CardProps, "id"> }>) {
    const { card } = message.data
    this.set((state) => {
      const player = state.players.find((player) => player.id === state.defender)
      state.tableCards.push({ attack: { ...card, id: card.suit + card.dignity, x: 0, y: 0 }, defend: null })
      if (player) {
        player.count -= 1
        return { players: state.players }
      }
      return state
    })
  }
  attacked(message: SocketMessage<{ attacker: number, card: Omit<CardProps, "id"> }>) {
    const { card, attacker } = message.data
    this.set((state) => {
      const player = state.players.find((player) => player.id === attacker)
      state.tableCards.push({ attack: { ...card, id: card.suit + card.dignity, x: 0, y: 0 }, defend: null })
      if (player) {
        player.count -= 1
        return { players: state.players }
      }
      return state
    })
  }
  defended(message: SocketMessage<{ card: CardPair }>) {
    const { card } = message.data
    console.log(message);
    this.set((state) => {
      const player = state.players.find((player) => player.id === state.defender)
      const tableCards = state.tableCards.map((cardPair) => {
        const id = card.attack.suit + card.attack.dignity
        if (id === cardPair.attack.id) {
          return {
            ...cardPair,
            attack: cardPair.attack,
            defend: {
              ...card.defend as TableCardType,
              id: card.defend!.suit + card.defend!.dignity, x: 0, y: 0
            }
          }
        }
        return cardPair
      })
      if (player) {
        player.count -= 1
        return {
          players: state.players,
          tableCards
        }
      }
      return state
    })
  }
  player_win(message: SocketMessage<{ player: number }>) {
    const { player } = message.data
    this.set((state) => {
      let newPlayer = state.player
      let currentState = state.currentState
      if(!state.player) return state
      if(state.player.id === player) {
        newPlayer = {
          ...state.player,
          status: PlayerStatus.WIN
        }
        currentState = GameState.WAIT
      }
      const players = state.players.map((player) => {
        if (player.id === state.attacker) {
          return {
            ...player,
            status: PlayerStatus.WIN
          }
        }
        return player
      })
      return {
        player: newPlayer,
        players,
        currentState
      }
    })
  }
}

class GameStateHandler {
  constructor(
    private set: (partial: GameStore | Partial<GameStore> | ((state: GameStore) => GameStore | Partial<GameStore>), replace?: boolean | undefined) => void,
    private get: () => GameStore
  ) { this.get }

  awaitReadyHandler(readyPlayers: number[]) {
    this.set({ currentState: GameState.AWAIT_READY })
    this.set((state) => {
      if (!state.player) return state
      const player = {
        ...state.player,
        status: PlayerStatus.EMPTY,
      }
      let readyStatus = false
      if (readyPlayers.includes(state.player.id)) {
        player.status = PlayerStatus.READY
        readyStatus = true
      }
      else {
        player.status = PlayerStatus.EMPTY
        readyStatus = false
      }
      const players = state.players.map((player) => {

        if (readyPlayers.includes(player.id)) {
          return {
            ...player,
            readyStatus: true,
            status: PlayerStatus.READY
          }
        }
        return {
          ...player,
          readyStatus: false,
          status: PlayerStatus.EMPTY
        }
      })
      return {
        players,
        player,
        readyStatus
      }
    })
  }
  prepareHandler() {
    this.set((state) => {
      if (!state.player) return state
      const player = {
        ...state.player,
        status: PlayerStatus.EMPTY,
      }
      const players = state.players.map((player) => {
        return {
          ...player,
          status: PlayerStatus.EMPTY
        }
      }) as EnemyPlayer[]
      return {
        player,
        players
      }
    })
  }

  statusHandler(game_state_data: PlayerTurnEvent) {
    const {
      attacker,
      defender,
      neighbor,
      attacker_turn_ended,
      defender_can_defend,
      neighbor_turn_ended,
      neighbor_can_attack
    } = game_state_data

    const { player, players } = this.get()
    let status = PlayerStatus.EMPTY
    let currentState = GameState.WAIT

    const newPlayers = players.map((player) => {
      if (player.status === PlayerStatus.WIN) {
        return {
          ...player,
          status: PlayerStatus.WIN
        }
      }
      if (player!.id === attacker) {
        if (attacker_turn_ended) {
          status = !defender_can_defend ? PlayerStatus.PASS : PlayerStatus.BITO
        } else {
          status = !defender_can_defend ? PlayerStatus.TOSS : PlayerStatus.ATTACK_STATUS
        }
      } else if (player!.id === defender) {
        if (!defender_can_defend) {
          status = PlayerStatus.TAKE
        } else {
          status = PlayerStatus.DEFEND_STATUS
        }
      } else if (player!.id === neighbor) {
        status = !defender_can_defend ? PlayerStatus.TOSS : PlayerStatus.EMPTY
        if(neighbor_can_attack) {
          status = !defender_can_defend ? PlayerStatus.TOSS : PlayerStatus.ATTACK_STATUS
        }
        if (neighbor_turn_ended) {
          status = !defender_can_defend ? PlayerStatus.PASS : PlayerStatus.BITO
        }
      } else {
        status = PlayerStatus.EMPTY
      }
      return {
        ...player,
        status
      }
    })
    // console.log(`NEW PLAYERS: ${JSON.stringify(newPlayers)}`)
    if (player!.status === PlayerStatus.WIN) {
      return {
        status: PlayerStatus.WIN,
        currentState: GameState.WAIT,
        players: newPlayers
      }
    }

    if (player!.id === attacker) {
      if (attacker_turn_ended) {
        status = !defender_can_defend ? PlayerStatus.PASS : PlayerStatus.BITO
        currentState = !defender_can_defend ?GameState.PLAYER_TOSS :GameState.BITO
      } else {
        status = !defender_can_defend ? PlayerStatus.TOSS : PlayerStatus.ATTACK_STATUS
        currentState = !defender_can_defend ? GameState.PLAYER_TOSS : GameState.ATTACK
      }
    } else if (player!.id === defender) {
      if (!defender_can_defend) {
        status = PlayerStatus.TAKE
        currentState = GameState.PICKUP
      }
      else {
        status = PlayerStatus.DEFEND_STATUS
        currentState = GameState.DEFEND
      }
    } else if (player!.id === neighbor) {
      status =  PlayerStatus.EMPTY
      currentState = GameState.WAIT
      if(neighbor_can_attack) {
        status = !defender_can_defend ? PlayerStatus.TOSS : PlayerStatus.ATTACK_STATUS
        currentState = !defender_can_defend ?GameState.PLAYER_TOSS :GameState.ATTACK
      }
      if(neighbor_turn_ended) {
        status = !defender_can_defend ? PlayerStatus.PASS : PlayerStatus.BITO
        currentState = !defender_can_defend ? GameState.PASS : GameState.BITO
      }
    }


    return {
      status,
      currentState,
      players: newPlayers
    }
  }

  playerTurnHandler(game_state_data: PlayerTurnEvent) {
    const {
      attacker,
      table,
      defender,
      neighbor,
      deadline
    } = game_state_data
    const { status, currentState, players } = this.statusHandler(game_state_data)
    this.set((state) => {
      const attackCards = state.attackCards
      const tableCards = table.map((card) => ({
        attack: {
          ...card.attack,
          id: card.attack.suit + card.attack.dignity,
          x: attackCards[card.attack.id] ? attackCards[card.attack.id].x : 0,
          y: attackCards[card.attack.id] ? attackCards[card.attack.id].y : 0
        },
        defend: card.defend ? {
          ...card.defend,
          id: card.defend.suit + card.defend.dignity,
          x: 0,
          y: 0
        } : null
      }))
      const { player } = state
      if (!player) return state
      if (attacker === state.player?.id) {
        const newPlayer = {
          ...player,
          status
        }
        return {
          tableCards,
          currentState,
          player: newPlayer,
          attacker,
          defender,
          players,
          deadline
        }
      } else if (defender === state.player?.id) {
        const newPlayer = {
          ...player,
          status
        }
        return {
          tableCards,
          currentState,
          player: newPlayer,
          players,
          attacker,
          defender,
          deadline
        }
      } else if (neighbor === state.player?.id) {
        const newPlayer = {
          ...player,
          status
        }
        return {
          tableCards,
          currentState,
          player: newPlayer,
          players,
          attacker,
          defender,
          deadline
        }
      } else {
        const newPlayer = {
          ...player,
          status
        }
        return {
          tableCards,
          currentState,
          player: newPlayer,
          players,
          attacker,
          defender,
          deadline
        }
      }
    })
  }

  // playerTossHandler(game_state_data: TossEvent) {
  //   const { attacker, defender, table, attacker_turn_ended, neighbor } = game_state_data
  //   const tableCards = table.map((card) => ({
  //     attack: {
  //       ...card.attack,
  //       id: card.attack.suit + card.attack.dignity,
  //       x: 0,
  //       y: 0
  //     },
  //     defend: card.defend ? {
  //       ...card.defend,
  //       id: card.defend.suit + card.defend.dignity,
  //       x: 0,
  //       y: 0
  //     } : null
  //   }))
  //   this.set((state) => {
  //     const { player } = state
  //     if (!player) return state
  //     // const bitoStatus = "BITO"
  //     // const attackerStatus = "🏹"
  //     // const tossStatus = "TAKE"
  //     const players = state.players.map((player) => {
  //       if (player.id === defender) {
  //         const status = attacker_turn_ended && attacker !== neighbor ?
  //           PlayerStatus.DEFEND_STATUS : PlayerStatus.TAKE
  //         return {
  //           ...player,
  //           status,
  //         }
  //       }
  //       else if (player.id === attacker) {
  //         const status = attacker_turn_ended && attacker !== neighbor ? PlayerStatus.BITO : PlayerStatus.TOSS
  //         return {
  //           ...player,
  //           status: status,
  //         }
  //       }
  //       else if(player.id === neighbor && attacker_turn_ended) {
  //         return {
  //           ...player,
  //           status: PlayerStatus.TOSS,
  //         }
  //       }
  //       return player
  //     })

  //     if (player.id === attacker) {
  //       const status = attacker_turn_ended && attacker !== neighbor ? PlayerStatus.BITO : PlayerStatus.TOSS
  //       return {
  //         currentState: attacker_turn_ended ? GameState.WAIT : GameState.PLAYER_TOSS,
  //         player: {
  //           ...player,
  //           status,
  //         },
  //         tableCards,
  //         players
  //       }
  //     }
  //     else if (player.id === defender) {
  //       const status = attacker_turn_ended && attacker !== neighbor ? PlayerStatus.DEFEND_STATUS : PlayerStatus.TAKE
  //       return {
  //         currentState: attacker_turn_ended && attacker !== neighbor ? GameState.DEFEND : GameState.PICKUP,
  //         player: {
  //           ...player,
  //           status,
  //         },
  //         tableCards,
  //         players
  //       }
  //     }
  //     else if (player.id === neighbor && attacker_turn_ended) {
  //       return {
  //         currentState: GameState.PLAYER_TOSS,
  //         player: {
  //           ...player,
  //           status: PlayerStatus.TOSS,
  //         },
  //         tableCards,
  //         players
  //       }
  //     }
  //     return {
  //       players,
  //       tableCards
  //     }

  // })
  // }


  gameover(fool: number) {
    this.set((state) => {
      const players = state.players.map((player) => {
        if (player.id === fool) {
          return {
            ...player,
            status: PlayerStatus.FOOL
          }
        }
        return player
      })
      if(state.player!.id === fool) {
        return {
          player: {
            ...state.player as Player,
            status: PlayerStatus.FOOL
          },
        }
      }
      return {
        players,
        currentState: GameState.WAIT
      }
    })
  }

}
