import * as React from "react";
const SvgTimer = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 20, height: 23, viewBox: "0 0 20 23", fill: "none", ...props }, /* @__PURE__ */ React.createElement("circle", { cx: 9.34011, cy: 12.8094, r: 9.34011, fill: "white", style: {
  fill: "white",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 6.13779, width: 6.40465, height: 2.13488, rx: 1.06744, fill: "white", style: {
  fill: "white",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 16.9875, y: 1.86816, width: 4.26033, height: 2.13488, rx: 1.06744, transform: "rotate(45 16.9875 1.86816)", fill: "white", style: {
  fill: "white",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 8.27267, y: 6.40479, width: 2.13488, height: 7.47209, rx: 1.06744, fill: "#323131", style: {
  fill: "#323131",
  fill: "color(display-p3 0.1961 0.1922 0.1922)",
  fillOpacity: 1
} }));
export default SvgTimer;
