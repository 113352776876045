import JD from '@/shared/assets/img/JD.png'
import JC from '@/shared/assets/img/JC.png'
import JS from '@/shared/assets/img/JS.png'
import JH from '@/shared/assets/img/JH.png'
import QD from '@/shared/assets/img/QD.png'
import QC from '@/shared/assets/img/QC.png'
import QS from '@/shared/assets/img/QS.png'
import QH from '@/shared/assets/img/QH.png'
import KD from '@/shared/assets/img/KD.png'
import KC from '@/shared/assets/img/KC.png'
import KS from '@/shared/assets/img/KS.png'
import KH from '@/shared/assets/img/KH.png'

import { CardTemplate } from "../CardTemplate"


const imagesJ: { [key: string]: string } = {
  'SUIT_DIAMOND': JD,
  'SUIT_HEART': JH,
  'SUIT_SPADE': JS,
  'SUIT_CLUB': JC
}

const imagesQ: { [key: string]: string } = {
  'SUIT_DIAMOND': QD,
  'SUIT_HEART': QH,
  'SUIT_SPADE': QS,
  'SUIT_CLUB': QC
}

const imagesK: { [key: string]: string } = {
  'SUIT_DIAMOND': KD,
  'SUIT_HEART': KH,
  'SUIT_SPADE': KS,
  'SUIT_CLUB': KC
}

const dignities: { [key: number]: [string, { [key: string]: string }] } = {
  11: ['J', imagesJ],
  12: ['Q', imagesQ],
  13: ['K', imagesK],
}



export const CardImage = ({ suit, dignity }: { suit: string, dignity: number }) => {
  const images = dignities[dignity][1]
  return (
    <CardTemplate dignity={dignities[dignity][0]} suit={suit}>
      <div className="py-[4px] flex justify-center items-center h-full">
        <img className="h-full w-full" src={images[suit]} alt="" />
      </div>
    </CardTemplate>
  )
}
