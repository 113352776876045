import { BgCard } from "@/shared"
import { motion } from 'framer-motion'
import useDisplayingCards from "../../lib/useDisplayingCards";
import React from "react";


export const EnemyHand = React.memo(({ count }: { count: number }) => {
  const displayingCards = useDisplayingCards<number[]>(Array.from({ length: count }))
  const calculateCardStyle = (index: number) => {
    if (count === 1) {
      return {}
    }
    const maxRotation = 30;
    const maxOffset = 30;
    const rotationStep = maxRotation / (count - 1 || 1);
    const offsetStep = maxOffset / (count - 1 || 1);
    const rotation = -maxRotation / 2 + rotationStep * index;
    const offset = -maxOffset / 2 + offsetStep * index;

    return {
      transform: `rotate(${rotation}deg) translateX(${offset}px)`,
      zIndex: index,
    };
  };
  return (
    <div className=" text-[5px] absolute bottom-[20px] right-[10px] w-full  z-10 flex rotate-180">
      {displayingCards.map((_, index) => (
        <motion.div
          key={index}
          initial={{
            x: 200,
            y: -300
          }}
          animate={{
            x: 0,
            y: 0,
            transition: { duration: 0.1 }
          }}>
          <div
            className="absolute w-[35px] h-[60px]"
            style={calculateCardStyle(index)}
          >
            <BgCard />
            {/* <Card img={BgBlue} /> */}
          </div>
        </motion.div>
      ))}
    </div>
  )
})
