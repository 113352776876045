import { TableCardType as TableCardType } from "@/entities/Game";
import { motion, useAnimation } from 'framer-motion';
import { useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";
import { Card } from "@/shared/";
type Props = {
  isDefending?: boolean
} & TableCardType

export const TableCard: React.FC<Props> = ({ x, y, isDefending, suit, dignity }) => {
  const ref = useRef<HTMLDivElement>(null)
  const controls = useAnimation()

  useEffect(() => {
    if (!ref.current) return
    const div = ref.current.getBoundingClientRect()
    const relativeX = x * 2 - div.left;
    const relativeY = y * 2 - div.top;
    controls.set({ x: relativeX, y: relativeY })
    controls.start({ x: 0, y: 0, scale: 1, opacity: 1 })
  }, [x, y, controls]);

  return (
    <motion.div
      ref={ref}
      initial={{ x: x, y: y, opacity: 0.5 }}
      animate={controls}
      className={twMerge("absolute h-[130px] w-[90px] flex justify-center")}>
      <div className={twMerge("rotate-[-10deg]  h-full w-full shadow-[10px_10px_25px_rgba(0,0,0,0.7)] table-card",
        isDefending && "rotate-[10deg]",
      )}>
        <Card dignity={dignity} suit={suit} />
      </div>
    </motion.div>
  )
}
