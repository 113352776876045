import { HandCard } from "../HandCard"
import { AnimatePresence, motion } from "framer-motion"
import { useGameStore } from "@/entities/Game"
import useDisplayingCards from "../../lib/useDisplayingCards"
import { CardProps } from "@/shared/ui/Cards"
import { useEffect, useRef } from "react"


export const Hand = () => {
  const { hand, fromTable } = useGameStore()
  const prevCards = useRef<CardProps[]>([])
  const displayedCards = useDisplayingCards<CardProps>(hand, fromTable)
  const totalCards = displayedCards.length
  // const cardWidth = 90
  const maxRotation = 3;
  const maxShift = Math.min(290, 48 * totalCards);
  const verticalShift = 5;
  // const xStep = maxShift / totalCards
  // const overlap = cardWidth - xStep
  // const totalWidth = cardWidth + (totalCards) * (cardWidth - overlap) - 15
  // const windowWidth = window.innerWidth
  // const left = (windowWidth - totalWidth) / 2

  useEffect(() => {
    prevCards.current = displayedCards
  }, [displayedCards])

  if (totalCards === 0) return <div></div>

  return (
    <div style={{ left: "8px" }} className="relative z-40 bottom-[30px] h-[132px] pl-[15px]">
      <AnimatePresence initial={false}>
        {displayedCards.map((item, index) => {
          let rotation = -maxRotation + (maxRotation * 2 * index) / (totalCards);
          let translateX = (index * maxShift) / (totalCards);
          let translateY = Math.abs(rotation) * verticalShift / maxRotation;
          if (index === 0) {
            translateX = 0
            translateY = 0
            rotation = -3
          }
          // const isNewCard = prevCards.current.find(card => card.id === item.id) === undefined
          const isNewCard = !prevCards.current.find(card => card.id === item.id);
          const prevIndex = prevCards.current.findIndex(card => card.id === item.id);
          const indexChange = prevIndex - index;
          // const prevRotate = calculateRotation(prevIndex, totalCards, maxShift)
          // const rotate = calculateRotation(index, totalCards, maxRotation)

          return <motion.div
            key={index + item.id}
            initial={isNewCard ? {
              x: fromTable ? -200 : -400,
              y: -330,
            } : indexChange !== 0 ? {
              x: (prevIndex * maxShift) / (totalCards),
              y: Math.abs(-maxRotation + (maxRotation * 2 * prevIndex) / (totalCards)) * verticalShift / maxRotation,
              rotate: -maxRotation + (maxRotation * 2 * index) / (totalCards),
            } : undefined}
            animate={{
              x: translateX,
              y: translateY,
              rotate: rotation,
              transition: { duration: 0.3 },
              zIndex: 99
            }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 30,
              mass: 1,
            }}
            layout
          >
            <HandCard index={index}
              {...item} />
          </motion.div>
        })}
      </AnimatePresence>
    </div>
  )
}
