import { CardTemplate, SuitIcon } from '../CardTemplate'


export const CardAce = ({ suit }: { suit: string }) => {
  return (
    <CardTemplate dignity='A' suit={suit}>
      <div className='flex h-full w-full justify-center items-center'>
        <SuitIcon suit={suit} />
      </div>
    </CardTemplate>
  )
}
