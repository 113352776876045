import { CardPair } from "@/entities/Game"
import { TableCard } from "@/entities/Game"
import styled from "@emotion/styled"
import { twMerge } from "tailwind-merge"


const getOrder = (index: number): number => {
  const positions: { [key: number]: number } = {
    0: 1,
    1: 2,
    2: 0,
    3: 4,
    4: 5,
    5: 3,
  }
  return positions[index] || 0
}

const getAttackRotation = (index: number): number => {
  const positions: { [key: number]: number } = {
    0: -1.9,
    1: -1.7,
    2: 7,
    3: -1.9,
    4: -4.8,
    5: 7,
  }
  return positions[index] || 0
}

const getDefendRotation = (index: number): number => {
  const positions: { [key: number]: number } = {
    0: 8.2,
    1: 7.4,
    2: 1.6,
    3: 3,
    4: 3,
    5: 3,
  }
  return positions[index] || 0
}
const StyledPair = styled.div<{ order: number }>`
  position: relative;
  width: 77px;
  order: ${props => props.order};
  `

const StyledAttackCard = styled.div<{ rotate: number }>`
  transform: ${props => `rotate(${props.rotate}deg)`};
  `
const StyledDefendCard = styled.div<{ rotate: number }>`
  padding-top: 5px;
  transform: ${props => `rotate(${props.rotate}deg)`};
`

export const TablePair: React.FC<{ id: string, index: number } & CardPair> = ({ attack, defend, id, index }) => {
  return (
    <StyledPair order={getOrder(index)} className={twMerge("table-pair", !defend && "attacking-card")} id={id}>
      <StyledAttackCard rotate={getAttackRotation(index)}>
        <TableCard {...attack} />
      </StyledAttackCard>
      {defend && <StyledDefendCard rotate={getDefendRotation(index)}>
        <TableCard {...defend} isDefending={true} />
      </StyledDefendCard>}
    </StyledPair>
  )
}
