import { CardProps } from "@/shared"

export type GameRoomConfig = {
  max_players: number
  game_mode: "normal" | "transferrable"
}



export type Card = {
    id: number
    rang: number
    img: string
    suit: string
}

export enum PlayerStatus {
  READY = 'Ready',
  BITO = 'BITO',
  TAKE = 'TAKE',
  ATTACK_STATUS = '⚔️',
  DEFEND_STATUS = '🛡️',
  DISCONNECT = '📵',
  TOSS = '🏹',
  FOOL = '🤡',
  EMPTY = '',
  PASS = 'PASS',
  WIN = '👑'
}

export type Hand = CardProps[]
export const enum GameState {
    AWAIT_READY = 'AWAIT_READY',
    PLAYER_TURN = 'PLAYER_TURN',
    PREPARE = 'PREPARE',
    ATTACK = 'ATTACK',
    DEFEND = 'DEFEND',
    END_TURN = 'END_TURN',
    WAITING = 'WAITING',
    WAIT = 'WAIT',
    PICKUP = 'PICKUP',
    BITO = 'BITO',
    PASS = 'PASS',
    GAMEOVER = 'GAMEOVER',
    PLAYER_TOSS = 'PLAYER_TOSS',
}

export type CardPair = {
    attack: TableCardType,
    defend: TableCardType | null
}

export type GameAction = {
    name: string,
    data: unknown
}

export type Client = {
    id: number
    first_name: string
    username: string | null
    position: number
    readyStatus: boolean
    deadline: string | null
    status: PlayerStatus
    photo_url: string
}
export type EnemyPlayer = {
  count: number
} & Client

export type Player = {
  cards: CardProps[]
} & Client

export type RoomInfo = {
    players: Client[]
}



export type GameStore = {
  roomId: string | null
  socket: WebSocket | null
  config: GameRoomConfig
  readyStatus: boolean
  defender: null | number
  attacker: null | number
  setReadyStatus: (ready: boolean) => void
  connect: (roomId: string) => void
  disconnect: () => void
  endTurn: () => void
  sync: () => void
  pickupCards: () => void
  trump: Omit<CardProps, "id"> | null
  hand: CardProps[]
  fromTable: boolean
  attackCards: {[key: string]: TableCardType}
  tableCards: CardPair[]
  player: Client | null
  players: EnemyPlayer[]
  deck: number
  bito: {x: number, y: number}[],
  hasMadeMove: boolean
  currentState: GameState
  setCurrentState: (state: GameState) => void
  addAttackingCard: (card: TableCardType) => void
  shiftCard: (card: TableCardType) => void
  defendCard: (card: TableCardType, cardId: string) => void
  removeCardFromHand: (cardId: string) => void
  sendProblem: () => void
  fool: number | null | string
  get mode(): "normal" | "transferrable"
  error: string | null
  deadline: string | null
}

export type TableCardType = {
    x: number
    y: number

}& CardProps
