import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: "https://f4d21608a61e2619ce46915f517da565@o4508370619138048.ingest.de.sentry.io/4508370624315472",
    integrations: [],
  });
}




ReactDOM.createRoot(document.getElementById('root')!).render(
  <App />

)
