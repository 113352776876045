// import { EnemyPlayer } from "../Game"
import { PlayerAvatar } from ".."
//@ts-ignore
import EnemyCards from '@/shared/assets/svg/enemyCards.svg?react'
import { getStatusColor, useGlowAnimation } from "@/entities/Player/lib"
import styled from "@emotion/styled"
import { PlayerStatus } from "../Game"


const StyledContainer = styled.div<{ online: boolean, glowAnimation?: boolean }>`
  opacity: ${props => props.online ? 1 : 0.5};
  border-radius: 13px;

  ${({ glowAnimation }) => glowAnimation};
`

const StyledCount = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 600;
  color: #FFFFFF;
  display: flex;
  gap: 4px;
  line-height: 14px;
`

export const Enemy = ({ username, count, status, photo_url, online }:
  { status: string, username: string, count: number, photo_url: string, online: boolean }) => {
  const statusColor = getStatusColor(status as PlayerStatus)
  const glowAnimation = useGlowAnimation(statusColor) as any
  console.log(`Enemy status: ${status}, ${username}`);
  return (
    <StyledContainer online={online} glowAnimation={glowAnimation}>
      <PlayerAvatar img={photo_url} name={username} status={status} />
      <StyledCount>{count}<EnemyCards /></StyledCount>
    </StyledContainer>
  )
}
