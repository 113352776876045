// @ts-ignore
import Diamonds from '@/shared/assets/svg/diamonds.svg?react'
// @ts-ignore
import Hearts from '@/shared/assets/svg/hearts.svg?react'
// @ts-ignore
import Spades from '@/shared/assets/svg/spades.svg?react'
// @ts-ignore
import Clubs from '@/shared/assets/svg/clubs.svg?react'
import styled from '@emotion/styled'

export type CardProps = {
  id: string
  dignity: string
  suit: string
}

export const SuitIcon = ({ suit, className }: { suit: string, className?: string }) => {
  const suitIcons: Record<string, JSX.Element> = {
    "SUIT_HEART": <Hearts />,
    "SUIT_DIAMOND": <Diamonds />,
    "SUIT_SPADE": <Spades />,
    "SUIT_CLUB": <Clubs />
  }
  const icon = suitIcons[suit]

  return <div className={`[&>svg]:size-[0.5em] ${className}`} >
    {icon}
  </div>
}

const CardMark = ({ dignity, suit }: { dignity: string, suit: string }) => {
  const color = suit === "SUIT_HEART" || suit === "SUIT_DIAMOND" ? "#BC1E24" : "#211C1D"
  return (
    <div className="text-[15px] px-[2px] tracking-[-2px] max-w-[32px] font-bold flex flex-col items-center justify-center"
      style={{ color }}
    >
      {dignity}
      <div className='text-[20px]'>
        <SuitIcon suit={suit} />
      </div>
    </div >
  )
}


const CardInside = styled.div`
  width: 100%;
  height: 100%;
  font-size: 35px;
  flex-grow: 1;
  padding: 10px 0;
`

export const CardTemplate = ({ dignity, suit, children }: { dignity: string, suit: string, children: React.ReactNode }) => {

  return (
    <div className="relative bg-white  rounded-[10px] w-full h-full  flex">
      <div className='grow-0'>
        <CardMark dignity={dignity} suit={suit} />
      </div>
      <CardInside>{children}</CardInside>
      <div className="grow-0 rotate-180">
        <CardMark dignity={dignity} suit={suit} />
      </div>
    </div>
  )
}
