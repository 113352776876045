import { PlayerStatus, useGameStore } from "@/entities/Game"
import { Enemy, EnemyLayout } from "@/entities";
import styled from "@emotion/styled";





const StyledPlayers = styled.div`
  position: relative;
  width: 100%;
  padding: 0 11px;
`



const StyledPlayer = styled.div`
  width: min(63px, 25vw);
  height: min(69px, 15vh);
  position: relative;
  border-radius: 13px;
  border: 0.678px solid #3D3D3D;
  background: rgba(46, 46, 46, 0.95);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
`






export const EnemyPlayers = () => {

  const { players, config } = useGameStore()

  const capacity = config.max_players
  return (
    <StyledPlayers>
      <EnemyLayout playersCount={capacity - 1}>
        {
          Array.from({ length: capacity - 1 }).map((_, index) => {
            const player = players[index]
            return <StyledPlayer
              key={index}>
              {
                player ? <Enemy
                  online={!(player.status === PlayerStatus.DISCONNECT)}
                  username={player.first_name}
                  count={player.count}
                  status={player.status}
                  photo_url={player.photo_url} /> :
                  <div className="text-white">+</div>
              }
            </StyledPlayer>
          })
        }
      </EnemyLayout>
    </StyledPlayers>
  )
}
