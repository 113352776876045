//@ts-ignore
import TimerSVG from '@/shared/assets/svg/timer.svg?react'
import { hapticFeedback } from '@telegram-apps/sdk';

import React from 'react'


export const Timer = ({ deadline }: { deadline: string }) => {
  // const { currentState, tableCards } = useGameStore()

  const [seconds, setSeconds] = React.useState(30)
  // const hapticFeedback = initHapticFeedback();
  React.useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const deadlineTime = new Date(deadline).getTime();
      const diffInSeconds = Math.floor((deadlineTime - now) / 1000);
      return diffInSeconds >= 0 ? diffInSeconds : 0;
    };

    const interval = setInterval(() => {
      const remainingSeconds = calculateTimeLeft();
      if (import.meta.env.PROD) {
        if (remainingSeconds % 10 === 0) {
          hapticFeedback.notificationOccurred('error')
          hapticFeedback.notificationOccurred('error')
        }
      }

      setSeconds(remainingSeconds);

      if (remainingSeconds === 0) {
        clearInterval(interval);
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [deadline])

  return (
    <div className='flex items-center gap-[10px] text-[20px] font-medium'>
      <TimerSVG />
      <span>0:{seconds > 9 ? seconds : '0' + seconds}</span>
    </div>
  )
}
