import { CardTemplate, SuitIcon } from '../CardTemplate'




export const CardTen = ({ suit }: { suit: string }) => {
  return (
    <CardTemplate dignity='10' suit={suit}>
      <div className='flex flex-col gap-[5px] justify-between h-full w-full'>
        <div className="grid grid-cols-3 grid-rows-2 gap-y-[10px] flex-1">
          <div className="col-start-1 flex justify-center items-center">
            <SuitIcon suit={suit} />
          </div>
          <div className="col-start-3  flex justify-center items-center">
            <SuitIcon suit={suit} />
          </div>
          <div className="row-start-1 col-start-2 row-span-2 flex justify-center items-center">
            <SuitIcon suit={suit} />
          </div>
          <div className="row-start-2 col-start-1  flex justify-center items-center">
            <SuitIcon suit={suit} />
          </div>
          <div className="row-start-2 col-start-3  flex justify-center items-center">
            <SuitIcon suit={suit} />
          </div>
        </div>

        <div className="grid grid-cols-3 grid-rows-2 gap-y-[10px] flex-1 rotate-180">
          <div className="col-start-1 flex justify-center items-center">
            <SuitIcon suit={suit} />
          </div>
          <div className="col-start-3  flex justify-center items-center">
            <SuitIcon suit={suit} />
          </div>
          <div className="row-start-1 col-start-2 row-span-2 flex justify-center items-center">
            <SuitIcon suit={suit} />
          </div>
          <div className="row-start-2 col-start-1  flex justify-center items-center">
            <SuitIcon suit={suit} />
          </div>
          <div className="row-start-2 col-start-3  flex justify-center items-center">
            <SuitIcon suit={suit} />
          </div>
        </div>
      </div>
    </CardTemplate >
  )
}
