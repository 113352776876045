import React from "react"

const Enemy1 = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex justify-center">{children}</div>
  )
}

const Enemy2 = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex justify-between">{children}</div>
  )
}

const Enemy3 = ({ children }: { children: React.ReactNode }) => {
  const childrenArray = React.Children.toArray(children);
  return (
    <div className="flex justify-between">
      <div className="translate-y-[50%]">{childrenArray[0]}</div>
      <div>{childrenArray[1]}</div>
      <div className="translate-y-[50%]">{childrenArray[2]}</div>
    </div>
  )
}

const Enemy4 = ({ children }: { children: React.ReactNode }) => {
  const childrenArray = React.Children.toArray(children);
  return (
    <div className="flex justify-between">
      <div className="flex flex-col gap-[20px]">
        <div className="translate-x-[50%]">
          {childrenArray[0]}
        </div>
        {childrenArray[1]}
      </div>
      <div className="flex flex-col gap-[20px]">
        <div className="translate-x-[-50%]">
          {childrenArray[2]}
        </div>
        {childrenArray[3]}
      </div>
    </div>
  )
}

const Enemy5 = ({ children }: { children: React.ReactNode }) => {
  const childrenArray = React.Children.toArray(children);
  return (
    <div className="flex justify-between">
      <div className="flex flex-col gap-[20px] translate-y-[50%]">
        <div className="translate-x-[50%]">
          {childrenArray[0]}
        </div>
        {childrenArray[1]}
      </div>
      <div>{childrenArray[2]}</div>
      <div className="flex flex-col gap-[20px] translate-y-[50%]">
        <div className="translate-x-[-50%]">
          {childrenArray[3]}
        </div>
        {childrenArray[4]}
      </div>
    </div>
  )
}




export const EnemyLayout = ({ playersCount, children }: { playersCount: number } & React.PropsWithChildren) => {
  console.log(playersCount)
  const layouts: Record<number, React.ReactNode> = {
    1: <Enemy1>{children}</Enemy1>,
    2: <Enemy2>{children}</Enemy2>,
    3: <Enemy3>{children}</Enemy3>,
    4: <Enemy4>{children}</Enemy4>,
    5: <Enemy5>{children}</Enemy5>,
  }

  return (
    <>
      {layouts[playersCount]}
    </>
  )
}
